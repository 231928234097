import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../../static/images/logo/logo.svg';
import logoMobile from '../../static/images/logo/logo-mobile.svg';
import MenuMobile from './MenuMobile';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    };
  }


  state = { data: "", wow: [] };


  componentDidMount=()=>{
    let socket = new WebSocket("wss://stream.binance.com/stream");

    socket.onopen = function (e) {
      socket.send(
        JSON.stringify({
          id: 1,
          method: "SUBSCRIBE",
          params: ["!miniTicker@arr@3000ms"],
        })
      );
    }



    socket.onmessage = (event) => {
      console.log(11)

      // this.setState({data:JSON.stringify(daa)})
      let daa = JSON.parse(event.data).data;

      try {
        var btc = daa.filter((element) => element.s == "BTCUSDT");

        this.setState({
          bitcoin: parseFloat(btc[0].c).toFixed(2),
          btcper: parseFloat(((btc[0].c - btc[0].o) / btc[0].c) * 100).toFixed(2),
        });
      } catch {}

      try {
        var eth = daa.filter((element) => element.s == "ETHUSDT");
        this.setState({ eth: parseFloat(eth[0].c).toFixed(2),
            ethper: parseFloat(((eth[0].c - eth[0].o) / eth[0].c) * 100).toFixed(2),
         });
      } catch {}

      try {
        var bnb = daa.filter((element) => element.s == "BNBUSDT");
        this.setState({ bnb: parseFloat(bnb[0].c).toFixed(2),
            bnbper: parseFloat(((bnb[0].c - bnb[0].o) / bnb[0].c) * 100).toFixed(2), });
      } catch {}

      try {
        var eos = daa.filter((element) => element.s == "EOSUSDT");
        this.setState({ eos: parseFloat(eos[0].c).toFixed(2),
            eosper: parseFloat(((eos[0].c - eos[0].o) / eos[0].c) * 100).toFixed(2), });
      } catch {}

      try {
        var xrp = daa.filter((element) => element.s == "XRPUSDT");

        this.setState({ xrp: parseFloat(xrp[0].c).toFixed(2),
            xrpper: parseFloat(((xrp[0].c - xrp[0].o) / xrp[0].c) * 100).toFixed(2) });
      } catch {}

      try {
        var bch = daa.filter((element) => element.s == "BCHUSDT");
        this.setState({ bch: parseFloat(bch[0].c).toFixed(2),
            bchper: parseFloat(((bch[0].c - bch[0].o) / bch[0].c) * 100).toFixed(2), });
      } catch {}

      try {
        var ltc = daa.filter((element) => element.s == "LTCUSDT");
        this.setState({ ltc: parseFloat(ltc[0].c).toFixed(2),
            ltcper: parseFloat(((ltc[0].c - ltc[0].o) / ltc[0].c) * 100).toFixed(2), });
      } catch {}
    };


  }



  toggleMenu = menuActive => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive
    }));
  };

  render() {
    const config = this.props.data.configJson;
    return (
      <>
      <div className="header">
        <div className="container">
          <div className="logo">
            <Link to="/">
              {/* EarnWithRk */}
              <img height={config.logo.desktop_height} alt={config.logo.alt} src={config.logo.desktop} />
            </Link>
          </div>
          <div className="logo-mobile">
            <Link to="/">
              <img width={config.logo.mobile_height} alt={config.logo.alt} src={config.logo.mobile} />
            </Link>
          </div>
          <MenuMobile active={this.state.menuActive} />
          <Menu />
          <Hamburger toggleMenu={this.toggleMenu} />
        </div>
      </div>
      <div className="ticker">
        
        <div className={this.state.btcper > 0 ? "green" : "red"}>
      BTC ${this.state.bitcoin}
          </div>
          <div className={this.state.btcper > 0 ? "green" : "red"}>
          ETH ${this.state.eth}
          </div>

          <div className={this.state.bnbper > 0 ? "green" : "red"}>
          BNB ${this.state.bnb}
          </div>

          <div className={this.state.xrpper > 0 ? "green" : "red"}>
          XRP   ${this.state.xrp}
          </div>

          <div className={this.state.ltcper > 0 ? "green" : "red"}>
          LTC ${this.state.ltc}
          </div>

      </div>
      </>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        configJson {
          logo {
            alt
            desktop
            mobile
            desktop_height
            mobile_height
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);
